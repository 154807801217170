.jki-logo {
	width: 100px;

	@screen md {
		width: 160px;
	}

	@screen lg {
		width: 180px;
	}
}
