.jki-navigation-modal-overlay {
	@apply fixed z-40 inset-0 bg-white opacity-0 transition-opacity duration-default;

	&--after-open {
		@apply opacity-100;
	}

	&--before-close {
		@apply opacity-0;
	}

	@screen md {
		@apply overflow-hidden bg-black bg-opacity-25;
	}

	@screen 2xl {
		@apply mx-auto;
		max-width: theme('screens.2xl.min');
	}
}
