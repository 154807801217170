.jki-collapsed {
	@apply -mt-6;

	&__wrapper {
		overflow: hidden;
		transition: height 500ms ease-in-out;

		&[hidden] {
			display: none;
			height: 0;
		}
	}

	&__trigger {
		@apply block w-full opacity-0 bg-transparent hover:underline focus:underline transition-opacity duration-default text-right;
		transition-property: opacity;
		transition-duration: 500ms;
	}

	&--active &__trigger {
		opacity: 1;
	}
}
