@keyframes jki-accordion-fadein {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.jki-accordion {
	$b: #{&};

	@apply my-2 border-b;

	&__item {
		@apply bg-gray-50;

		page-break-inside: avoid;
		break-inside: avoid-page;

		&:first-child {
			page-break-before: avoid;
			break-before: avoid-page;
		}
	}

	&__button {
		margin-top: -1px;

		&:hover,
		&:focus {
			@apply bg-gray-200;
		}

		&[aria-expanded='true'] #{$b}__arrow,
		&[aria-selected='true'] #{$b}__arrow {
			transform: rotate(90deg);
		}
	}

	&__panel {
		@apply bg-gray-50 p-0 pb-12;
		animation: jki-accordion-fadein 350ms ease-in;

		.frame-nn-accordion {
			@apply p-4 pr-6 pl-8;

			&.frame-type-uploads header h2 {
				@apply m-0;
			}

			// Exception for empty header frames and nested frames
			&.frame-type-header,
			.frame-nn-accordion {
				@apply p-0;
			}
		}
	}

	&__content-wrapper {
		transition: outline 1s ease-out;
		outline: 2px solid transparent !important;

		&--focused {
			outline: 2px solid #fbc707 !important;
		}
	}
}
