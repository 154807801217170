@import "../_variables";

@mixin infobox () {

	border-top: 1px solid $borderColor;
	border-bottom: 1px solid $borderColor;
	background-color: $backgroundHighlightColor;
	padding: 0.8em;
	margin: 0.8em auto;
	width: 90%;

	&--left {
		width: 33%;
		float: left;
		margin-right: 1em;
		margin-left: 0;
	}
	&--right {
		width: 33%;
		float: right;
		margin-left: 1em;
		margin-right: 0;
	}
}
