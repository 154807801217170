.person {
	&__item {
		padding: 0.6em 0;
	}

	&__organisation,
	&__name {
		font-weight: $semibold;
	}

	&__organisation,
	&__street-address,
	&__zip,
	&__city,
	&__name,
	&__description,
	&__email,
	&__phone,
	&__description {
		display: block;
	}

	&__email,
	&__phone {
		display: inline-block;
	}

	&__images,
	&__icon {
	}

	&__address {
	}

	&__item, &__info {

		ul {
			margin: 0;
			padding: 0;
		}

		li {
			list-style: none;
		}
	}
}

.tt_address_detail {
	ul {
		padding: 0;
		margin: 0
	}

	li {
		list-style: none;
	}

	.content-address, .content-building {
		padding-top: 1em;
		font-style: normal;
	}

	.content-image {
		display: table-cell;
		padding-right: 1em;

		img {
			width: 200px;
			height: auto;
		}
	}

	.content-contact {
		display: table-cell;
		vertical-align: top;
	}

	.description {
		padding-top: 1em;
	}

}

.kompendium .person__item svg{
	display: none
}
