.jki-svg-icon {
	$b: #{&};

	@apply fill-current;

	&--size-default {
		width: 40px;
		height: 40px;
	}

	&--size-sm {
		width: 22px;
		height: 22px;
	}
}
