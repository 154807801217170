@import "../mixins/clearfix";
@import "../mixins/info";

.jki-content .ce-gallery {
	margin: 0.3em 0;
	overflow-x: auto;

	&.ce-border img {
		border: 1px solid $borderColor;
	}

	&[data-ce-columns='1'] {
		display: block;

		.ce-row {
			display: block;

			.ce-column {
				display: inline-block;
				padding: 0;

				img {
					width: auto;
					max-width: 100%;
					vertical-align: initial;
				}
			}
		}
	}

	&[data-ce-columns='2'] .ce-column {
		max-width: 50%;

		.ce-gallery .ce-column:nth-child(2) {
			padding-right: 0;
		}
	}

	&[data-ce-columns='3'] .ce-column {
		max-width: 33%;

		.ce-gallery .ce-column:nth-child(3) {
			padding-right: 0;
		}
	}

	&[data-ce-columns='4'] .ce-column {
		max-width: 25%;

		.ce-gallery .ce-column:nth-child(4) {
			padding-right: 0;
		}
	}

	.ce-row {
		padding: 10px 0;
		@include clearfix;


		.ce-column {
			float: left;
			width: auto;
			vertical-align: top;
			font-size: 12px;
			overflow: hidden;
			white-space: normal;
			padding-right: 10px;
			padding-top: 10px;
		}
		&:first-child {
			padding-top: 0;
		}

		&:last-child {
			padding-bottom: 0;
		}

	}

	.typo3-fancybox {
		display: inline-block;
		margin: 2px;

		&:focus {
			outline: 2px solid $linkColor;
		}
	}

	img {
		width: 100%;
		height: auto;
	}
	iframe {
		border-width: 0;
	}

	figcaption {
		@include info;
		padding: 0.2em;
	}
}

.ce-center {
	text-align: center;
}

.ce-gallery--scroll figure {
	padding-bottom: 10px;
}

.ce-gallery--scroll figcaption {
	display: none;
}

.ce-gallery--scroll img {
	width: auto;
}

@screen sm {
	.ce-intext.ce-right .ce-gallery {
		float: right;
		margin-left: 20px;
	}

	.ce-intext.ce-left .ce-gallery {
		float: left;
		margin-right: 20px;
	}
}
