// print style
@media print {

	.jki-navbar {
		display: none !important;
		box-shadow: none !important;
	}

	.jki-page {
		background-color: transparent !important;
	}

	#typo3-preview-info,
	#TSFE_ADMIN_PANEL_FORM {
		display: none !important
	}

	h1.jki-publikation {
		font-size: 2.5em !important;
		padding-bottom: 1.5rem;
		font-weight: 600 !important;
		padding-top: 20mm!important;
		color: black;
	}

	.jki-content {
		padding: 0!important;
		padding-left: 13mm !important;
	}

}

// "JKI Aktuell" style page layout publication
body.jki-layout-publication {
	background: url($imagePath + "print-background-jkiaktuell.svg") no-repeat top left;
	background-size: 95% auto;


	.jki-content__inner {
		h1 {
			font-size: 24px !important;
		}

		h1.jki-publikation {
			font-size: 32px !important;
			font-weight: 600 !important;
			padding-top: 3.5rem !important;
			padding-bottom: 0 !important;
			color: black;
		}

		h2 {
			font-size: 20px !important;
			margin-bottom: 0 !important;
			margin-top: 0 !important;
		}

		.frame-nn-accordion {
			padding: 0 !important;
		}

		.news-text-wrap,
		.news-ce-wrap {

			ul, ol {
				margin:  1em;
				list-style: none;
				padding-left: 1em;
			}

			ul {
				list-style: none;
				padding-left: 1em;

				li::before {
					content: "•";
					display: inline-block;
					width: 1em;
					margin-left: -1em
				}
				p {
					padding: 0;
					margin: 0;
				}
			}

			ol {
				padding-left: 1.5em;
				list-style-position: outside;

				li {
					display: list-item;
					list-style-type: decimal
				}
			}
		}

		.news-additional-info {
			padding: 0 !important;
		}

		.news-additional-info,
		.image-caption {
			color: black !important;
			font-size: 10px !important;
		}

		a {
			border: 0 !important;
		}
	}

}

