.jki-a-to-z {
	&__headline {
		margin: 40px 18px 5px 18px !important;

		.links + & {
			margin-top: 8px !important;
		}
	}

	&__list {
		transition: outline 300ms ease-out;
		outline: 2px solid transparent !important;

		&--focused {
			transition: outline 1s ease-in;
			outline: 2px solid #fbc707 !important;
		}
	}

	&__register {
		margin: 12px -5px 0 -5px;
		padding: 5px 5px 7px 5px;
		border-bottom: 1px solid #ccc;

		position: sticky;
		top: 0;

		.scrolled-up & {
			padding-top: 55px;
		}

		overflow: auto;

		list-style: none;
		text-align: center;
		background: white;

		&__item {
			display: inline-block;
			margin: 10px 0 0 0;
			border-left: 1px solid #ccc;

			&:first-child {
				border-left: 0;
			}
		}

		&__button {
			display: inline-block;
			padding: 1px 1px 2px 0;
			border: 0;
			background: none;
			text-align: center;
			cursor: pointer;
			vertical-align: top;

			&:hover,
			&:focus {
				background: #eee;
			}
		}

		font-size: 15px;

		@media (min-width: 1400px) {
			padding: 0 5px 7px 5px;
			font-size: $baseFontSize;

			&__button {
				width: 33px;
				padding: 3px 1px 3px 0;
			}
		}
	}
}
