@import "../mixins/info";

.jki-content {

	.news {
		&-additional-info {
			@include info;
			padding: 0.2em;
			border-top: 1px solid $borderColor;
			text-align: right;
			margin-top: 3em;
		}

		.teaser-text {
			font-weight: $semibold;
		}

		h2 {
			margin: 0.8em 0 0.2em;
		}

	}

	.news-list {
		&-more {
			color: $primaryColor;
			text-decoration: none;
			border: none;
			margin: -3px -8px;
			padding: 3px 8px;
			display: block;

			&:hover {
				background-color: $backgroundHighlightColor;
			}
		}
	}


	// Var content elements
	.frame-type {
		&-list .news-single .news-text-wrap {

			ul, ol {
				list-style: none;
				margin: 1em;
				padding-left: 1em;

				p {
					padding: 0;
					margin: 0;
				}
			}

			ul {
				list-style: none;

				li::before {
					content: "•";
					display: inline-block;
					width: 1em;
					margin-left: -1em;
				}
			}

			ol {
				list-style-position: outside;

				li {
					display: list-item;
					list-style-type: decimal;
				}
			}

			.contenttable {
				padding: 0.5em 0;
			}

		}

		&-html iframe {
			width: 100%;
			height: 600px;
			border: 0;
		}
	}


	.f3-widget-paginator {
		padding: 0;
		margin: 0;

		& li {
			list-style: none;
			display: inline-block;

			&.current {
				font-weight: $semibold;
				display: inline-block;
				padding: 0.3em;
				min-width: 1.5em;
				text-align: center;
			}

			&:hover,
			&:focus {
				border: none;
				background-color: $backgroundHighlightColor;
			}

			a {
				color: $primaryColor;
				text-decoration: none;
				display: inline-block;
				padding: 0.3em;
				min-width: 1.5em;
				text-align: center;
				border: none;
			}
		}

	}

}
