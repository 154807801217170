// print style
@media print {

	.jki-navbar {
		display: none !important;
		box-shadow: none !important;
	}

	.jki-page {
		background-color: transparent !important;
	}

	#typo3-preview-info,
	#TSFE_ADMIN_PANEL_FORM {
		display: none !important
	}

	.jki-content {
		padding: 0!important;
		padding-left: 13mm !important;
	}

	.jki-accordion__panel {
		opacity: 1!important;
		padding: 0!important;

		.frame-nn-accordion {
			padding: 0.5rem 1rem;
		}

	}

	.jki-accordion__button:first-child {
		border-top-width: 1px!important;
	}

}


