@import "../mixins/info";

.tx-solr-autosuggest {
	border: 1px solid $secondaryColor;
	background: #fff;
	overflow: auto;

	strong {
		font-weight: $bold;
	}

	.autocomplete-suggestion {
		padding: 2px 5px;
	}

	.autocomplete-suggestion,
	.autocomplete-suggestion div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.autocomplete-selected {
		background-color: #E7E5E4;
	}

	.autocomplete-group {
		padding: 10px 5px 3px;
	}

	.autocomplete-group strong {
		display: block;
		border-bottom: 1px solid #999;
	}

	a {
		color: $linkColor;
		text-decoration: none;
		border-bottom: 1px dotted $linkColor;
	}
}

#tx-solr-search {
	.tx-solr-q {
		background: url($imagePath + "search.svg") no-repeat 95% 50%;
		background-size: 18px;
	}
	.tx-solr-suggest {
		cursor: default;
		padding: 3px;
		height: auto;
		border: 1px solid $secondaryColor;
	}
}

.jki-content__inner {

	.results-list {
		padding: 0;
	}

	.results-entry {
		padding: 0.8em 0;
		list-style: none;

		h2, h3, h4, p {
			padding: 0;
			margin: 0;
		}

		&__link {
			margin: -5px -8px;
			padding: 5px 8px;
			color: $primaryColor;
			text-decoration: none;
			display: block;
			border: none;

			&:hover,
			&:focus {
				border: none;
				background-color: $backgroundHighlightColor;
			}
		}

		&__image {
			float: left;
			margin-right: 1em;
		}

		&__tags {
			@include info;
			padding: 0.2em;
		}

		&__categories {
			@include info;
			padding: 0.2em;
			padding-top: 0.7em!important;
		}
	}

	#tx-solr-search {

		.tx-solr-search-form {
			margin-top: 1em;

			.tx-solr-q {
				padding-right: 2.5em;
			}

		}

		.result-found {
			@include info;
		}

		.pagination {
			padding: 0;
			margin: 0;

			& li {
				list-style: none;
				display: inline-block;

				&.active {
					font-weight: $bold;
				}

				&:hover,
				&:focus {
					border: none;
					background-color: $backgroundHighlightColor;
				}

				a {
					color: $primaryColor;
					text-decoration: none;
					display: inline-block;
					padding: 0.3em;
					min-width: 1.5em;
					text-align: center;
					border-bottom: 0;
				}
			}

		}

	}

}

.kompendium .jki-content__inner {

	.results-highlight {
		box-shadow: 4px 0 0 #cedec8, -3px 0 0 #cedec8;
		background-color: #cedec8;
		font-weight: $semibold !important;
		border-radius: 0.1em;
	}
}

.intranet .jki-content__inner {

	.results-highlight {
		box-shadow: 4px 0 0 #d8e9f7, -3px 0 0 #d8e9f7;
		background-color: #d8e9f7;
		font-weight: $semibold !important;
		border-radius: 0.1em;
	}
}
