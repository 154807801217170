@import "../mixins/clearfix";
@import "../mixins/info";
@import "../mixins/infobox";
@import "../mixins/table";

.intranet.jki-layout-home {

	// CE: Text -> Links
	.frame-type-text {

		h2 {
			margin-bottom: 0;
		}

		p {
			margin-top: 13px;
			a {
				padding-top: 0;
				color: black;
				text-align: right;
				display: block;
				font-weight: 200;
				border: none;
			}
			a:hover {
				text-decoration: underline;;
			}

		}
	}

	.jki-content__inner {
		h2 {
			font-size: 15px;
			line-height: 23px;
			color: $headerHome;
			border-bottom: 1px solid #fff;
			font-weight: 300;
			text-transform: uppercase;
			margin-bottom: 0 !important;

			&.with-bottom-line {
				border-bottom: 1px solid $borderColor;
			}
		}

		h3 {
			margin: 0;
		}

		p {
			margin-bottom: 0;
			font-weight: $regular;
		}

	}
}

.jki-content__inner {

		hr {
			display: block;
			height: 1px;
			border-top: 1px solid $borderColor;
			border-right-width: 0;
			border-bottom-width: 0;
			border-left-width: 0;
			margin: 1em 0;
			padding: 0;
		}

		.frame, .ui-accordion {
			width: 100%;
		}

		h1, h2, h3, h4, h5 {
			font-size: $baseFontSize;
			font-weight: $semibold;
			margin-bottom: 5px;
		}
		h1 {
			font-size: 30px;
			line-height: 30px;
			margin-bottom: 20px;
		}
		h2 {
			font-size: 23px;
			line-height: 30px;
			margin-top: 40px;
			margin-bottom: 20px;
		}
		h3 {
			margin-top: 19px
		}
		p {
			margin-top: 0;
			margin-bottom: 13px;
			hyphens: auto;

			a {
				color: $linkColor;
				text-decoration: none;
				border-bottom: 1px dotted $linkColor;

				&.typo3-fancybox {
					border-bottom: none;
				}
			}
		}

		a {
			&.phone,
			&.email,
			&.download,
			&.external-link,
			&.internal-link,
			&.back-link,
			&.show-all-link {
				color: $linkColor;
				text-decoration: none;
				border-bottom: 1px dotted $linkColor;
			}

			&.link {
				&-internal,
				&-internal-blank,
				&-download,
				&-folder,
				&-external,
				&-external-blank,
				&-mail,
				&-telephone {
					color: $linkColor;
					text-decoration: none;
					border-bottom: 1px dotted $linkColor;
				}
				//.&-download{
				//	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cpath d='M11.4115 12.0083l5.0741-4.94726 1.27144 1.23965-7.37886 7.19439L3 8.30066l1.2715-1.2396 5.14 5.012V2h2v10.0083zM1 16.97645h18v1.74359H1v-1.74359z' /%3E%3C/svg%3E");
				//	background-repeat: no-repeat;
				//	background-position: right;
				//	padding-right: 20px;
				//}
				&-external-blank{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cpath fill-rule='evenodd' d='M10.92494 5L9.02183 7H3v10h10v-5.94627l2-2.10062V19H1V5z' /%3E%3Cpath d='M16.06831 2.82901l-5.44154-.06246L10.61334 1l8.2624.08799.08749 8.26194-1.76656-.01351-.06089-5.33408-8.33688 8.33688-1.1204-1.1204 8.38981-8.38981z' /%3E%3C/svg%3E");
					background-repeat: no-repeat;
					background-position: right;
					padding-right: 20px;
				}
				&-external{
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20'%3E%3Cpath d='M8.84221 4.72163L8.82907 3l8.08531.08575L17 11.13759l-1.72869-.01317-.05959-5.19843L5.09638 16 4 14.9081 14.16713 4.7825z' /%3E%3C/svg%3E");
					background-repeat: no-repeat;
					background-position: right;
					padding-right: 20px;
				}
			}
		}
		.frame-space-after-medium {
			height: 1em;
		}

		.frame-layout {
			&-3 {
				float: right;
				width: 40%;
			}
		}

		.fixed-table-header thead {
			position: sticky;
			position: -webkit-sticky;
			top: 0;
			z-index: 2;

			td, th {
				border: 0!important;
				border: none!important;
				background: linear-gradient(to top, $borderColorDark 0, $borderColorDark 2px, transparent 2px);
			}
		}

		// Var content elements
		.frame-type {
			&-list.nnjkiintranetbase_jobs,
			&-textpic,
			&-text {
				ul, ol {
					margin:  0.3em;
					list-style: none;
					padding-left: 1em;
					display: block;
				}

				ul {
					list-style: none;
					padding-left: 1em;

					li::before {
						content: "•";
						display: inline-block;
						width: 1em;
						margin-left: -1em
					}
					p {
						padding: 0;
						margin: 0;
					}
				}

				ol {
					padding-left: 1.5em;
					list-style-position: outside;

					li {
						display: list-item;
						list-style-type: decimal
					}
				}

				.contenttable {
					padding: 0.5em 0;
				}

				.ce-textpic.ce-intext {
					ul, ol  {
						display: flow-root;
					}
				}

			}

			&-html iframe {
				width: 100%;
				height: 600px;
				border: 0;
			}

			&-teaser {
				figcaption {
					@include info;
					padding: 0.2em;
				}
			}
		}

		.frame-type-list,
		.nnjkiintranetbase_jobs,
		.frame-type-tx_nnjkiintranet_events {

			.infobox {
				padding: 1em 1.4em;
				margin-top: 1.5em;
				border-bottom: 1px solid $borderColor;
				border-top: 1px solid $borderColor;
				background: $listBackgroundColor;
			}

			.show-all {
				color: $primaryColor;
				text-align: right;
				display: block;
				font-weight: $light;
				border: none;
				&:hover {
					text-decoration: underline;
				}
			}

			.more {
				color: $primaryColor;
				text-decoration: none;
				border: none;
				margin: -3px -8px;
				padding: 3px 8px;
				display: block;

				&:hover {
					background-color: $backgroundHighlightColor;
				}
			}

			.img {
				max-width: 100%;
				height: auto;
				border: 1px solid $imageBorderColor;
			}
		}


		/**************************************************/
		/** Tabellen
		/** .contenttable = Tabelle aus RTE
		/** .ce-table = Tabelle Contentelement Tabelle
		/**************************************************/
		.contenttable
		{
			@include contenttable;

			tfoot {
				@include info;
				padding: 0.2em;
			}
		}

		.ce-table {
			@include contenttable;
			width: 100%;

			tfoot {
				@include info;
				padding: 0.2em;
			}
		}

		.links {
			padding: 0;
			margin: 0;
			display: table;
			border-collapse: collapse;
			width: 100%;

			p {
				padding: 0;
				margin: 0;
			}

			.links__item {
				background-color: $listBackgroundColor;
				list-style: none;
				border-top: 1px solid $borderColor;
				display: table-row;

				&:last-child {
					border-bottom: 1px solid $borderColor;
					margin: 0 0 1px;
				}
				&:hover,
				&:focus {
					background-color: $listBackgroundColorActive;
				}

			}

			.links__link {
				color: $primaryColor;
				text-decoration: none;
				width: 100%;
				display: block;
				padding: 0.5em 1em;
				border-bottom: 0;

				&--internal {
					background: url($imagePath + "arrow-r.svg") no-repeat 99% 50%;
					padding-right: 1.4em;
				}

				&--download {
					background: url($imagePath + "download.svg") no-repeat 99% 30%;
					padding-right: 2em;
					background-position: 100% 0.5ex;
				}
				&--external-link-new-window {
					background: url($imagePath + "arrow-tr.svg") no-repeat 99% 30%;
					padding-right: 1.2em;
				}

				.link {
					vertical-align: top;
					padding-top: 5px;

					&__accessibility,
					&__icon,
					&__filesize,
					&__title,
					&__language,
					&__date {
						display: table-cell;
						padding-right: 0.5em;
					}
					&__accessibility,
					&__filesize,
					&__language,
					&__date {
						white-space: nowrap;
					}
					&__accessibility,
					&__language,
					&__title {
						font-weight: $semibold;
					}
					&__description {
						font-weight: $regular;
						hyphens: auto;
						word-break: break-word;
					}

					&__accessibility::after {
						content: ")";
					}
					&__accessibility::before {
						content: "(";
					}

					&__icon {
						width: 20px;
						padding-top: 0;
					}
					&__title {
						width: 100%;
						hyphens: auto;
						word-break: break-word;
					}
					&__filesize {
						width: 60px;
					}
					&__date {
						width: 90px;
						vertical-align: top;
					}
					&__language {
						width: 40px;
						font-size: $fontSizeSmall;
						line-height: 24px;
					}
					&__accessibility {
						width: 40px;
						font-size: $fontSizeSmall;
						line-height: 24px;
					}
				}

			}
		}

		.infobox {
			@include infobox;
		}

		.highlighting {
			&--gray {
				color: #000000;
				box-shadow: 4px 0 0 $gray, -3px 0 0 $gray;
				background: $gray;
				border-radius: 0.1em;
			}
		}

		.font-color {
			&--red {
				color: $red;
			}
			&--green {
				color: $green;
			}
		}

	}

@media screen and (max-width: 640px) {

	.jki-content__inner {

			.infobox--left {
				width: 100%;
				margin-bottom: 1em;

				&--left,
				&--right {
					float: none;
					margin-right: 0;
					margin-left: 0;
				}

			}

			.contenttable-linearized{
				@include contenttable-linearized();
			}

			.links__link {

				&--internal,
				&--download,
				&--external-link-new-window {
					padding-right: 0 !important;
				}

				.link {
					&__title {
						display: block !important;
					}

					&__accessibility,
					&__filesize,
					&__language,
					&__date {
						font-weight: normal !important;
						line-height: normal !important;
						width: auto !important;
						padding: 0.5rem 0.25rem 0.75rem 0 !important;
						font-size: $fontSizeSmall;
					}

					&__accessibility::after {
						content: "" !important;
					}

					&__accessibility::before {
						content: "" !important;
					}
				}
			}
		}
}
