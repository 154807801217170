$imagePath:  "/assets/img/";

$stripeWidth: 6px;
$stripeWidthActive: 49px;
$stripeRoomWidth: 66px;
$stripeRoomWidthRight: 110px;
$contentWidth: 945px;
$contentMaxWidth: 1334px;

$headerHeight: 354px;
$headerCarouselControlsHeight: 80px;

$pageElementSpacing: 15px;

$marginColumnWidth: 28%;
$altMainColumnWidth: 90%;
$paddingContentColumn:  2.3%;
$wideMarginColumnWidth: 28.5%;
$wideMarginMainColumnWidth: 100% - $marginColumnWidth;
$wideMainColumnWidth: 100% - $marginColumnWidth - $paddingContentColumn;


$primaryColor: black;
$secondaryColor: #D6D6D6;
$borderColor: #CDCDCE;
$borderColorDark: #0f0f0f;
$imageBorderColor: #979797;
$linkColor: #165096;

$headerHome: #78716c;
$infoColor: #78716c;


$listBackgroundColor: #FAFAFA;
$listBackgroundColorActive: #EFEFEF;

$pageBackgroundColor: white;
$backgroundHighlightColor: #f5f5f4;
$backgroundHighlightColorActive: #D6D6D6;

$red: #BE1E1E;
$green: #008543;
$gray: $backgroundHighlightColor;

$caption-font-size: 14px;
$caption-color: #78716c;

$fontSizeSmall: 	12px;
$infoFontSize: 		14px;
$baseFontSize: 		1.19rem;
$baseLineHeight: 	1.35;

$light: 	200;
$regular: 	300;
$semibold: 	600;
$bold: 		700;
$extrabold: 800;


$themedIcons: accordion-collapsed, accordion-expanded, breadcrumb-arrow, leaf, leaf-w, person, person-dummy;

$contentBackground: #d0eaf8;
$titleBackground: #B2D3F0;
$contentHoverBackground: #A1D5F1;

$themeColors: (
		water: (
				contentBackground: #d0eaf8,
				titleBackground: #B2D3F0,
				contentHoverBackground: #A1D5F1,
//				subNavActiveBackground: #A1D5F1,
//				subNavCurrentBackground: #A1D5F1,
				iconColor: #72c0ea,
//				searchButtonBackground: #3aa7e2,
//				searchButtonHoverBackground: #A1D5F1,
				titlePrefixBackground: #37a6e1,
//				headerCarouselControlsBackground: #A1D5F1
		)
);

$stripesColors: (
		water: (
				0: #5E95D6, 1: #5E95D6,
				2: #5E95D6,
				3: #5E95D6, 4: #5E95D6,
				5: #5E95D6, 6: #5E95D6,
				7: #AECAEA, 8: #AECAEA)
);
