.jki-navigation-modal {
	@apply absolute inset-0 z-40 bg-white overflow-x-hidden overflow-y-auto;
	min-height: 100vh;
	$width: 360px;
	transform: translate3d(0, 0, 0);

	@screen md {
		@apply shadow-2xl transition-transform duration-default;
		width: $width;
		left: auto;
		transform: translate3d(100%, 0, 0);

		&--after-open {
			transform: translate3d(0%, 0, 0);
		}

		&--before-close {
			transform: translate3d(100%, 0, 0);
		}
	}

	@screen 2xl {
		@apply left-1/2;
		margin-left: calc(calc(theme('screens.2xl.min') / 2) - #{$width});
	}
}
