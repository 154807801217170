.jki-dropdown-nav-item {
	@apply block relative z-10
	sm:px-3 md:px-4
	py-3 px-1 xs:px-2
	font-semibold leading-none
	group-hover:bg-gray-200
	group-focus-within:bg-gray-200;

	&__label {
		@apply block
		transition-transform duration-default transform
		group-focus-within:bg-gray-200
		has-focus-visible:-translate-x-2
		motion-reduce:transform-none;
	}

	&__button {
		@apply hidden
		has-focus-visible:flex
		items-center justify-center
		w-4 h-4
		absolute top-1/2 left-full -mt-2 -ml-4
		bg-gray-100
		text-gray-900
		z-20
		pointer-events-none;
	}
}
