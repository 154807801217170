@import "../mixins/typography";

.jki-bodytext {
	h1 {
		@include heading(1);
	}

	h2 {
		@include heading(2);
	}

	h3 {
		@include heading(3);
	}

	h4 {
		@include heading(4);
	}

	h5 {
		@include heading(5);
	}

	h6 {
		@include heading(6);
	}

	p, ul, ol, table {
		@include paragraph;
	}

	ul, ol {
		@apply ml-7;
	}

	li {
		@apply my-2;
	}

	ul {
		@apply list-disc;
	}

	ol {
		@apply list-decimal;
	}

	h1, h2, h3, h4, h5, h6 {
		& + h1,
		& + h2,
		& + h3,
		& + h4,
		& + h5,
		& + h6 {
			@apply mt-2;
		}
	}

	header {
		h1, h2, h3, h4, h5, h6 {
			& + p {
				@apply -mt-2;
			}
		}
	}
}
