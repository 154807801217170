.jki-content__inner{

	.newssubmit {

		&__remove {

			&-link {
				position: relative;
				cursor: pointer;
				width: 25px;
				height: 25px;
				display: block;

				&::after,
				&::before {
					content: "";
					display: block;
					position: absolute;
					top: 10px;
					width: 20px;
					height: 3px;
					background-color: #ccc;
					transform-origin: center center;
					transition: background-color 300ms ease-in-out;
				}

				&:hover,
				&:focus {
					&::after,
					&::before {
						background-color: $linkColor;
					}
				}

				&::after {
					transform: rotate(-45deg);
				}

				&::before {
					transform: rotate(45deg);
				}
			}

		}

		fieldset {
			border: medium none !important;
			margin: 0 0 10px;
			min-width: 100%;
			padding: 0;
			font-weight: $semibold;
			width: 100%;

			div {
				padding: 0.3em 0;

				label {
					font-weight: $regular;
				}
			}

			legend {
				padding: 1.5em 0 0.5em;
			}
		}

		select,
		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="url"],
		textarea {
			width: 100%;
			border: 1px solid #cdcdce;
			background: #fff;
			margin: 0 0 5px;
			padding: 10px;
		}

		input[type="file"] {
			width: 100%;
		}

		select:hover,
		input[type="text"]:hover,
		input[type="email"]:hover,
		input[type="tel"]:hover,
		input[type="url"]:hover,
		textarea:hover {
			transition: border-color 0.3s ease-in-out;
			border: 1px solid #aaa;
		}

		textarea {
			height: 100px;
			max-width: 100%;
			resize: vertical;
		}

		input[type="submit"] {
			cursor: pointer;
			width: auto;
			border: 1px solid #cdcdce;
			background: #e2e3e6;
			color: #000;
			margin: 0 0 5px;
			padding: 10px 25px;
			font-size: 15px;
			border-radius: 3px;

			&:hover {
				background: #5e95d6;
				transition: background-color 0.1s ease-in-out;
				color: white;
			}
			&:active {
				box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
			}
		}

		select:focus,
		input:focus,
		textarea:focus {
			outline: 0;
			border: 1px solid #5e95d6;
		}
	}
}
