@mixin heading($level: 'base') {
	@apply mt-6 mb-2;

	@if ($level == 'section') {
		@apply leading-tight font-semibold text-gray-500 border-b;
	} @else {
		@apply leading-tight font-semibold;

		@if ($level == 1) {
			@apply text-4xl lg:text-6xl;
		}

		@if ($level == 2) {
			@apply text-3xl lg:text-4xl;
		}

		@if ($level == 3) {
			@apply text-2xl;
		}

		@if ($level == 4) {
			@apply text-xl;
		}

		@if ($level == 5) {
			@apply text-lg;
		}

		@if ($level == 6) {
		}
	}
}

@mixin paragraph() {
	@apply my-4 leading-relaxed;
}

@mixin list($list-style: disc) {
	@apply my-4 leading-tight;

	@if ($list-style == decimal) {
		@apply list-decimal;
	} @elseif ($list-style == disc) {
		@apply list-disc;
	} @else {
		@apply list-none;
	}
}
