@import "../_variables";

@mixin contenttable() {
	float: none;
	border-style: solid;
	border-width: 0;
	border-spacing: 0;
	border-collapse: collapse;
	background: none;
	border-color: $borderColor;
	table-layout: auto;

	caption {
		background-color: transparent;
		margin: 1.5em 0 .5em 0;
		font-weight: $semibold;
		text-align: center;

		p {
			margin: 0 !important;
		}
	}

	tbody th {
		font-weight: $semibold;
		vertical-align: middle;
		border: 1px solid $borderColor;
	}

	tbody tr th {
		border-bottom: 1px solid $borderColor;

		&:first-child {
			border-left: 0;
			padding-left: 0.5em;
		}

		&:last-child {
			border-right: 0;
			padding-right: 0.5em;
		}
	}

	thead {

		th {
			vertical-align: top;
			padding: 0.3em;
			line-height: normal;
			font-weight: $semibold;
			background-color: #fff;
			border: 1px solid $borderColor;
			border-top: 0;
			border-bottom: 2px solid $borderColorDark;

			&:first-child {
				border-left: 0;
				padding-left: 0.5em;
			}

			&:last-child {
				border-right: 0;
				padding-right: 0.5em;
			}
		}

		td {
			border-bottom: 2px solid $borderColorDark;
			background-color: #fff;
			border-top: 0;
		}
	}

	tbody {

		tr:nth-child(odd) {
			background-color: $listBackgroundColor;
		}

		tr:nth-child(even) {
			background-color: #fff;
		}

	}

	td {
		border: 1px solid $borderColor;
		vertical-align: top;
		padding: 0.3em;

		&:first-child {
			border-left: 0;
		}

		&:last-child {
			border-right: 0;
		}
	}
}

@mixin contenttable-linearized() {

	min-width: auto !important;
	width: 100% !important;

	td, th {
		display: list-item;
		list-style: none;
		width: auto !important;
		padding: 0.1em 0.8em !important;
		border-width: 0 0 1px 0 !important;
	}

	td, th {
		background: none;
		border-bottom: 1px solid $borderColor
	}

	th:last-child {
		border: none;
		background: linear-gradient(to top, $borderColorDark 0, $borderColorDark 2px, transparent 2px);
	}

	td:last-child {
		border-bottom: 1px solid $borderColor;
	}

	th, td {
		padding: 0.3em 0.8em !important;
	}

	td:last-of-type {
		padding-bottom: 1em !important;
	}

	td:first-of-type {
		padding-top: 1em !important;
	}

	tbody {

		tr:nth-child(2n+1) {
			background-color: #FAFAFA;
		}
	}
}

