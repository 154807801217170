.filter {
	padding: 0;
	margin: 0;

	&__item {
		position: relative;
		list-style: none;
		line-height: 1.6;

		&--active {
			font-weight: $semibold;

			&::after,
			&::before {
				content: "";
				display: block;
				position: absolute;
				left: -15px;
				top: 12px;
				width: 11px;
				height: 2px;
				background-color: #ccc;
				transform-origin: center center;
				transition: background-color 300ms ease-in-out;
			}

			&:hover,
			&:focus {
				&::after,
				&::before {
					background-color: $linkColor;
				}
			}

			&::after {
				transform: rotate(-45deg);
			}

			&::before {
				transform: rotate(45deg);
			}
		}

		.jki-content__main & {
			display: inline;
		}
	}
}

a.filter__link {
	border-bottom: 0;

	&:hover,
	&:focus {
		border-bottom: 1px dotted;
	}

	color: $linkColor;
	text-decoration: none;
	border-bottom: 1px dotted $linkColor;
}
