@import "photoswipe/dist/photoswipe.css";

.pswp {
	&__custom-caption {
		@apply
			hidden
			absolute left-1/2 bottom-4 transform -translate-x-1/2
			bg-black bg-opacity-75
			text-white text-base
			px-2 py-1
			rounded;

		width: calc(100% - 32px);
		max-width: 400px;

		&--visible {
			@apply block;
		}

		a {
			@apply text-inherit underline;
		}
	}
}
