@import "../mixins/typography";

.jki-heading {
	&--section {
		@include heading('section');
	}

	&--1 {
		@include heading(1);
	}

	&--2 {
		@include heading(2);
	}

	&--3 {
		@include heading(3);
	}

	&--4 {
		@include heading(4);
	}

	&--5 {
		@include heading(5);
	}

	&--6 {
		@include heading(6);
	}
}
