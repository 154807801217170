.jki-navbar {
	@apply
		fixed top-0 -mt-20 bg-gray-50 z-20 shadow-xl w-full transition-transform
		duration-default ease-in-out transform translate-y-0 scrolled-up:translate-y-20;

	display: none;

	.scrolled-up &,
	&.transition-active {
		display: block;
	}
}
