@import "flickity/css/flickity";

@import "tailwindcss/components";

@layer components {
	@import "variables";
	@import "helpers"; // TODO: Use tailwind instead

	// 2021 code:
	@import "blocks/accordion";
	@import "blocks/bodytext";
	@import "blocks/collapsed";
	@import "blocks/dropdown-nav-item";
	@import "blocks/heading";
	@import "blocks/lightbox";
	@import "blocks/logo";
	@import "blocks/modal";
	@import "blocks/modal-overlay";
	@import "blocks/navbar";
	@import "blocks/navigation-modal";
	@import "blocks/navigation-modal-overlay";
	@import "blocks/page";
	@import "blocks/svg-icon";

	// intranet only/legacy:
	@import "blocks/a-to-z";
	@import "blocks/content";
	@import "blocks/page";
	@import "blocks/person";
	@import "blocks/solr";
	@import "blocks/ce-gallery";
	@import "blocks/newssubmit";
	@import "blocks/news";
	@import "blocks/filter";

	html, body {
		&.jki--modal-open  {
			overflow: hidden;
		}
	}

	a:focus-visible  {
		outline-style: auto
	}

	*:focus-visible  {
		outline-color: $linkColor;
	}

	// override main nav: hide last item "Oft gesucht" on mobile. The page is still accessible through the hamburger nav.
	#main-nav > ul > li:last-child {
		@apply hidden md:flex;
	}

	.scrolled .scroll-to-top-button {
		@apply block;
	}

	@import "print";
	@import "jki-aktuell-publication.scss";
}
